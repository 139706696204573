import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Receive alerts when event tickets go on sale "
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">
            <p>Whether it’s a marketing conference, sports event or concert, getting tickets for yourself or for
            your company to entertain your clients can be problematic. The best seats for popular events sell fast,
              and on top of that, a lucrative Secondary Market has emerged making  it even more difficult to get the tickets you want. </p>
            <p>If you are working in this Secondary Market, a company looking for tickets for a popular conference in your industry, or a huge
              fan of an artist or athlete, using a sophisticated website monitoring platform can ensure you do not miss out whenever tickets go on sale.</p>
            <h4 className="bold-title">Get Alerts when a Date for an Event is Announced</h4>
            <p>Hexowatch can be used to monitor event pages and send you alerts as soon as dates are announced. If you are one of the first to know about an event,
              it can help you plan efficiently and organize your travel itinerary to the event.</p>
            <p>Interest in a big event leads to an increased demand for flights and accommodation, which drives prices up. Using a cutting-edge monitoring tool
              like Hexowatch will help you keep your costs under control.</p>

            <h4 className="bold-title">Be the first to buy event tickets</h4>
            <p>Depending on the nature of the event, tickets can sell out in hours if not minutes. Some people wait for a notification
            from a mailing list to learn about these events, but these mailing lists can be huge, and it takes time to send
            messages out to all the people subscribed to these lists. Therefore, it is quite common to get this important
              information when it is too late and when the tickets are already gone.</p>
            <p>With Hexowatch, you can create a page change monitor on the ticket page of the events you are interested in,
              and receive an alert as soon as tickets go on sale.</p>
            <h4 className="bold-title">A Step-by-Step Guide for Monitoring Ticketing Events on Hexowatch</h4>
            <p>You can start to monitor when tickets are released online in minutes:</p>

            <ul>
              <li>Step 1: Pick either the visual page monitor, the keyword monitor or the HTML element monitor to zone in on
              the availability status for that page (for example: ‘Unavailable’ and ‘Sold Out’ buttons. This helps
              you avoid getting unnecessary alerts from pages that are not connected to an event’s ticket selling.)
              </li>
              <li>
                Step 2: Define the frequency of the checks (you can pick from as little as every 5 minutes to once a day)
              </li>
              <li>Step 3: Get e-mail notifications when tickets go on sale for an event you are interested in.</li>
              <li>Step 4: Go to the relevant portal or website and buy your tickets!</li>
            </ul>

            <h4>Go beyond event availability monitoring</h4>

            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>
            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website, keep an archive of every
              change and turn any website into your own private data source accessing changes as a downloadable CSV file, google sheets or via Zapier.</p>

          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
